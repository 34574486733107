import React from 'react';

const ReloadButton = () => {
    const handleReload = () => {
        window.location.reload();
    };

    return (
        <button className='btn btn-outline-secondary' onClick={handleReload} >
            Get Another Quote
        </button>
    );
};

export default ReloadButton;