import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import ScrollToTop from './js/components/ScrollToTop'
import Layout from './js/components/Layout'
import Home from './js/pages/Home'
import About from './js/pages/AboutUs'
import Standard from './js/pages/StandardClean'
import Deep from './js/pages/DeepClean'
import Move from './js/pages/MoveInOut'
import Reviews from './js/pages/Reviews'
import PrivacyPolicy from './js/pages/PrivacyPolicy'
import Terms from './js/pages/Terms'
import Faqs from './js/pages/Faqs'
import ContactUs from './js/pages/ContactUs'
import Quote from './js/pages/Quote'
import CleaningChecklist from './js/pages/CleaningChecklist'
import Error404 from './js/components/Error404'


function App() {
  const poster = null

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home poster={poster} />} />
          <Route path="about-us" element={<About />} />
          <Route path="standard-clean" element={<Standard />} />
          <Route path="deep-clean" element={<Deep />} />
          <Route path="move-in-out-clean" element={<Move />} />
          <Route path="cleaning-checklist" element={<CleaningChecklist />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="faqs" element={<Faqs />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="quote" element={<Quote />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
