import cleaningAreas from "./cleaningAreas";

const initialValues = {
    firstname: '', 
    lastname: '', 
    email: '', 
    phone:'', 
    address:'',
    apt: '',
    city: '',
    state: '',
    zip_code: '',
    additional:'', 
    about_us: 'Google',
    frequency: '',
}

cleaningAreas.withQuantity.forEach(element => {
    initialValues[element.name] = element.min
    initialValues[`${element.name}_cleaning`] = 'standard'
    initialValues[`${element.name}_spot_wall`] = false
});

cleaningAreas.withoutQuantity.forEach(element => {
    element.type === 'always'?
    initialValues[element.name] = 'standard':
    initialValues[element.name] = 'none'
})

cleaningAreas.withoutQuantity.forEach(element => {
    if (element.name !== 'stairs'){
        initialValues[`${element.name}_spot_wall`] = false
    }
})

cleaningAreas.extrasWithQuantity.forEach(element => {
    initialValues[element.name] = 0
})

export default initialValues;