import React from 'react'
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import Container from 'react-bootstrap/Container';
import PageTitle from '../components/PageTitle';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

export default function ContactUs(){
    const [error, setError] = React.useState(null);
    const [submitted, setSubmitted] = React.useState(null);

    const initialValues = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    }

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().trim() 
            .min(1, 'First name cannot be empty or just whitespace')
            .max(50, 'First name must be at most 50 characters')
            .required('This field is required'),
        lastname: Yup.string().trim() 
            .min(1, 'Last name cannot be empty or just whitespace')
            .max(50, 'Last name must be at most 50 characters')
            .required('This field is required'),
        email: Yup.string()
            .email('Invalid email format')
            .required('This field is required'),
        phone: Yup.string()
            .matches(/^\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format XXX-XXX-XXXX')
            .required('This field is required'),
        subject: Yup.string().trim()
            .max(78, 'Subject field must be at most 78 characters'),
        message: Yup.string().trim()
            .min(1, 'Message cannot be empty or just whitespace')
            .max(2000, 'Subject field must be at most 2,000 characters')
            .required('This field is required'),
    })

    function handlePhoneChange(e, setFieldValue) {
        const { value } = e.target;
        if (/^[\d-]*$/.test(value) && value.length <= 12) {
            setFieldValue('phone', value);
        }
    }

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitted(null)
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/send-message/`, values);
            setError(null)
            setSubmitted(true);
        } catch (error) {
            if (error.response.data.non_field_errors){
                setError(error.response.data.non_field_errors[0])
            } else {
                setError('Error sending message. Please try again.')
            }
        } finally {
            setSubmitting(false)
        }
    };
    return(
        <Container>
            <PageTitle title='DHCS - Contact Us' />

            <h1>Contact Us</h1>
            <div className='contact'>
                <ul className='no-style-list'>
                    <li className='phone'>
                        <span>Phone</span><br />
                        302-525-8286
                    </li>
                    <li className='email'>
                        <span>Email</span><br />
                        juan2385<br />
                        @danishomecleaningservices.com
                    </li>
                    <li className='geo'>
                        <span>Address</span><br />
                        6 Cresson Avenue New Castle, DE 19720
                    </li>
                    <li className='time'>
                        <span>Operating Hours</span><br />
                        <span>Monday - Friday</span>
                        <span className='hours'>8AM - 6PM</span>
                        <span>Saturday</span>
                        <span className='hours'>8AM - 2PM</span>
                    </li>
                </ul>
                {
                    submitted?
                    <div className='contact--form contact--form-submitted'>
                        <p>We'll deliver your message. If you don't hear back from us soon, please feel free to reach out via text or call.</p>
                    </div>:
                    <Formik
                        initialValues = {initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ setFieldValue, isSubmitting, errors, touched, isValid }) => (
                            <Form className='contact--form'>
                                <p>We will get back to you ASAP!</p>
                                <section className='my-3'>
                                    <div>
                                        <Row>
                                            <Col sm={12} md={6} >
                                                <label htmlFor='firstname'>First Name</label>
                                                <Field id='firstname' name='firstname'  className={`form-control ${touched.firstname && errors.firstname ? 'is-invalid' : ''}`} />
                                                <ErrorMessage name='firstname'>
                                                    {msg => <div className="text-danger">{msg}</div>}
                                                </ErrorMessage>
                                            </Col>
                                            <Col sm={12} md={6} >
                                                <label htmlFor='lastname'>Last Name</label>
                                                <Field id='lastname' name='lastname'  className={`form-control ${touched.lastname && errors.lastname ? 'is-invalid' : ''}`} />
                                                <ErrorMessage name='lastname'>
                                                    {msg => <div className="text-danger">{msg}</div>}
                                                </ErrorMessage>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={12} md={6} >
                                                <label htmlFor='email'>Email</label>
                                                <Field id='email' name='email' type='email' className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`} />
                                                <ErrorMessage name='email'>
                                                    {msg => <div className="text-danger">{msg}</div>}
                                                </ErrorMessage>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <label htmlFor='phone'>Phone</label>
                                                <Field 
                                                    id='phone' 
                                                    name='phone'  
                                                    className={`form-control ${touched.phone && errors.phone ? 'is-invalid' : ''}`}
                                                    onChange={(e) => handlePhoneChange(e, setFieldValue)}
                                                    placeholder='123-456-7890'
                                                />
                                                <ErrorMessage name='phone'>
                                                    {msg => <div className="text-danger">{msg}</div>}
                                                </ErrorMessage>
                                            </Col>
                                        </Row>

                                        <div className='mb-3'>
                                            <label htmlFor='subject'>Subject</label>
                                            <Field id='subject' name='subject'  className={`form-control ${touched.subject && errors.subject ? 'is-invalid' : ''}`} />
                                        </div>

                                        <div className='mt-3 mb-4'>
                                            <label htmlFor="message">Message</label>
                                            <Field as="textarea" id="message" name="message" rows="5" className={`form-control ${touched.message && errors.message ? 'is-invalid' : ''}`} />
                                            <ErrorMessage name="message" component="div" className='text-danger' />
                                        </div>
                                    </div>
                                </section>


                                {/* Submit */}
                                <div className='quote--button mb-3'>
                                    <Button type='submit' variant='outline-primary' disabled={isSubmitting || !isValid || !touched}>
                                        {isSubmitting ? 'Sending Message...' : 'Send Message'}
                                    </Button>
                                </div>
                                {error && <p className='text-danger text-center'>{error}</p>}
                            </Form>
                        )}
                    </Formik>
                }
            </div>
        </Container>
    )
}