import React from 'react'
import Button from 'react-bootstrap/Button'
import { Field, ErrorMessage } from 'formik';
import formData from '../../quoteUtils/formData';
import cleaningAreas from '../../quoteUtils/cleaningAreas';

// Media
import bathroom from '../../../media/icons/bathroom.png';
import bedrooom from '../../../media/icons/bedroom.png';
import powder_room from '../../../media/icons/powder-room.png';


const WithQuantity = ({setFieldValue, values}) => {
    const icons = {
        bathrooms: bathroom,
        bedrooms: bedrooom,
        powder_rooms: powder_room
    }

    return (
        <>
            <p>Select the number of rooms and your preferred cleaning service</p>
            <div className='quote--withQuantity'>
                { cleaningAreas.withQuantity.map(item => (
                    <div key={`${item.name}-withQuantity`}>
                        <div className='quote--icon'>
                            <img src={icons[item.name]} alt={`${item.name} icon`} />
                        </div>
                        <p className='fw-600'>{item.label}</p>
                        <div className='quote--quantity'>
                            { values[item.name] === item.min ?
                                <Button variant='primary' size='sm' disabled aria-label={`Decrease ${values[item.label]} Count`} >
                                    <i className="bi bi-dash-lg"></i>
                                </Button>
                                :<Button variant='primary' size='sm'
                                    aria-label={`Decrease ${values[item.label]} Count`}
                                    onClick={() => setFieldValue(item.name, Math.max(item.min, values[item.name] - 1))}
                                >
                                    <i className="bi bi-dash-lg"></i>
                                </Button>
                            }
                            <p>{values[item.name]}</p>
                            { values[item.name] === item.max ?
                                <Button variant='primary' size='sm' disabled aria-label={`Increase ${values[item.label]} Count`} >
                                    <i className="bi bi-plus-lg"></i>
                                </Button>
                                :<Button variant='primary' size='sm'
                                    aria-label={`Increase ${values[item.label]} Count`}
                                    onClick={() => setFieldValue(item.name, Math.min(item.max, values[item.name] + 1))}
                                >
                                    <i className="bi bi-plus-lg"></i>
                                </Button>
                            }
                        </div>
                        <div className='my-3'>
                            <Field
                                as="select"
                                id={`${item.name}_cleaning`}
                                name={`${item.name}_cleaning`}
                                className={`form-control`}
                                aria-label={`Clenaing type for ${values[item.label]}`}
                                disabled={ values[item.name] === 0}
                            >
                                {formData['cleaning_type'].map((cleaning, index) => (
                                    <option key={cleaning.value || index} value={cleaning.value}>
                                        {cleaning.label}
                                    </option>
                                ))}
                            </Field>
                        </div>
                        <div className='my-3 form-check'>
                            <Field 
                                type="checkbox" 
                                name={`${item.name}_spot_wall`}  
                                className='form-check-input' 
                                disabled={ values[item.name] === 0} 
                                id={`${item.name}_spot_wall`} />
                            <label htmlFor={`${item.name}_spot_wall`} className='form-check-label' >Spot Wall Cleaning</label>
                        </div>
                        <ErrorMessage name={`${item.name}_cleaning`}>
                            {msg => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                        <ErrorMessage name={`${item.name}`}>
                            {msg => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                    </div>
                ))}
            </div>
        </>
    )
}

export default WithQuantity;