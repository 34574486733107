import React from 'react'
import QuoteReceipt from './QuoteReceipt'
import { QuoteContext } from '../../pages/Quote';

const BookingReceipt = () => {
    const {responseData} = React.useContext(QuoteContext);

    return (
        <div className='booking--receipt'>
            <p>You will receive a confirmation email regarding your booking request.
                If you don't hear back from us soon, please feel free to reach out via text or call.</p>
            <QuoteReceipt />
            <div>
                <p className='fw-600 ms-3'>Preferred Dates</p>
                <ol>
                    <li>{responseData['date1']} <span className='text-capitalize'>({responseData['time1']})</span></li>
                    <li>{responseData['date2']} <span className='text-capitalize'>({responseData['time2']})</span></li>
                    <li>{responseData['date3']} <span className='text-capitalize'>({responseData['time3']})</span></li>
                </ol>
            </div>
            <div className='ms-3'>
                <p className='fw-600'>Additional Info</p>
                <p>{responseData['additional']}</p>
                <p>{responseData['additional2']}</p>
            </div>
            <p>This is <span className='text-danger fw-500'>NOT</span> a booking.
            Dani's Home Cleaning Services will contact you to finalize the booking process.</p>
        </div>
    )
}

export default BookingReceipt
