import React from 'react'
import Table from 'react-bootstrap/Table';
import cleaningAreas from '../../quoteUtils/cleaningAreas';
import { QuoteContext } from '../../pages/Quote';

const QuoteReceipt = () => {
    const {responseData} = React.useContext(QuoteContext);

    return (
        <div className='receipt'>
            <div>
                <p>{responseData.firstname} {responseData.lastname}</p>
                <p>{responseData.email}</p>
                <p>{responseData.phone}</p>
                <p>{responseData.address}, {responseData.apt} {responseData.city}, {responseData.state}, {responseData.zip_code}</p>
            </div>

            <Table size="sm">
                <thead>
                    <tr>
                        <th>Description</th>
                        <th></th>
                        <th className='text-center'>QTY</th>
                    </tr>
                </thead>
                <tbody>
                    { cleaningAreas.withQuantity
                        .filter(item => responseData[item.name] > 0)
                        .map(item => (
                            <tr key={`${item.name}-response`}>
                                <td>{item.label}</td>
                                <td className='text-capitalize text-start'>
                                    {[responseData[`${item.name}_cleaning`]]} clean
                                    {responseData[`${item.name}_spot_wall`] && ' + Spot Wall Clean'}
                                </td>
                                <td>{responseData[item.name]}</td>
                            </tr>
                        ))
                    }
                    { cleaningAreas.withoutQuantity
                        .filter(item => responseData[item.name] !== 'none')
                        .map(item => (
                            <tr key={`${item.name}-response`}>
                                <td>{item.label}</td>
                                <td className='text-capitalize text-start'>
                                    {responseData[item.name]} clean
                                    {responseData[`${item.name}_spot_wall`] && ' + Spot Wall Clean'}
                                </td>
                                <td>1</td>
                            </tr>
                        ))
                    }
                    { cleaningAreas.withoutQuantity
                        .filter(item => responseData[item.name] === 'none' && responseData[`${item.name}_spot_wall`])
                        .map(item => (
                            <tr key={`${item.name}-response`}>
                                <td>{item.label}</td>
                                <td className='text-capitalize text-start'>
                                    Spot Wall Clean
                                </td>
                                <td>1</td>
                            </tr>
                        ))
                    }
                    { cleaningAreas.extrasWithQuantity
                        .filter(item => responseData[item.name] > 0)
                        .map(item => (
                            <tr key={`${item.name}-response`}>
                                <td className='text-capitalize'>{item.name}</td>
                                <td className='text-start'>{item.name === 'windows' ? '' : 'Inside'}</td>
                                <td>{responseData[item.name]}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>

            <div>
                <p className='fw-600 text-end'>Frequency:</p> 
                <p className='text-start'>{responseData['frequency']}</p>
                <p className='fw-600 text-end'>Duration:</p> 
                <p className='text-start'>{responseData['duration']}</p>
                <p className='fw-600 text-end'>TOTAL:</p> 
                <p className='text-start'>{responseData['quote']}</p>
            </div>
        </div>
    )
}

export default QuoteReceipt;