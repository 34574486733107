import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import Favicon from './Favicon';

export default function Layout(){
    return (
        <>  
            <Favicon />
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    )
}