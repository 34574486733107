import * as Yup from 'yup';
import formData from './formData';
import zipCodes from './zipCodes'

const zipCodesStr = zipCodes.map(num => num.toString());

const validationSchema = Yup.object().shape({
    oven: Yup.number()
    .required('This field is required')
    .min(0, 'Oven must be at least 0')
    .max(2, 'Oven must be at most 2'),

    refrigerator: Yup.number()
    .required('This field is required')
    .min(0, 'Refrigerator must be at least 0')
    .max(2, 'Refrigerator must be at most 2'),

    windows: Yup.number()
    .required('This field is required')
    .min(0, 'Windows must be at least 0')
    .max(50, 'Windows must be at most 50'),

    bedrooms: Yup.number()
    .required('This field is required')
    .min(0, 'Bedrooms must be at least 0')
    .max(10, 'Bedrooms must be at most 10'),

    bathrooms: Yup.number()
    .required('This field is required')
    .min(0, 'Bathrooms must be at least 0')
    .max(5, 'Bathrooms must be at most 5'),

    powder_rooms: Yup.number()
    .required('This field is required')
    .min(0, 'Powder rooms must be at least 0')
    .max(2, 'Powder rooms must be at most 2'),

    bedrooms_cleaning: Yup.string()
    .required('This bedrooms_cleaning field is required')
    .oneOf(formData['cleaning_type'].map(cleaning => cleaning.value), 'Please select a valid cleaning choice'),

    bathrooms_cleaning: Yup.string()
    .required('This bathrooms_cleaning field is required')
    .oneOf(formData['cleaning_type'].map(cleaning => cleaning.value), 'Please select a valid cleaning choice'),

    powder_rooms_cleaning: Yup.string()
    .required('This powder_rooms_cleaning field is required')
    .oneOf(formData['cleaning_type'].map(cleaning => cleaning.value), 'Please select a valid cleaning choice'),

    kitchen: Yup.string()
    .required('This field is required')
    .oneOf(formData['cleaning_type'].map(cleaning => cleaning.value), 'Please select a valid cleaning choice'),

    dining_room: Yup.string()
    .required('This field is required')
    .oneOf(formData['cleaning_type_none'].map(cleaning => cleaning.value), 'Please select a valid cleaning choice'),

    living_room: Yup.string()
    .required('This field is required')
    .oneOf(formData['cleaning_type_none'].map(cleaning => cleaning.value), 'Please select a valid cleaning choice'),

    family_room: Yup.string()
    .required('This field is required')
    .oneOf(formData['cleaning_type_none'].map(cleaning => cleaning.value), 'Please select a valid cleaning choice'),

    eat_in: Yup.string()
    .required('This field is required')
    .oneOf(formData['cleaning_type_none'].map(cleaning => cleaning.value), 'Please select a valid cleaning choice'),

    hallway: Yup.string()
    .required('This field is required')
    .oneOf(formData['cleaning_type_none'].map(cleaning => cleaning.value), 'Please select a valid cleaning choice'),

    office_study: Yup.string()
    .required('This field is required')
    .oneOf(formData['cleaning_type_none'].map(cleaning => cleaning.value), 'Please select a valid cleaning choice'),

    basement: Yup.string()
    .required('This field is required')
    .oneOf(formData['cleaning_type_none'].map(cleaning => cleaning.value), 'Please select a valid cleaning choice'),

    laundry_room: Yup.string()
    .required('This field is required')
    .oneOf(formData['cleaning_type_none'].map(cleaning => cleaning.value), 'Please select a valid cleaning choice'),

    sun_room: Yup.string()
    .required('This field is required')
    .oneOf(formData['cleaning_type_none'].map(cleaning => cleaning.value), 'Please select a valid cleaning choice'),

    stairs: Yup.string()
    .required('This field is required')
    .oneOf(formData['cleaning_type_none'].map(cleaning => cleaning.value), 'Please select a valid cleaning choice'),
    

    frequency: Yup.string()
        .oneOf(formData['frequency'], 'Please select a valid choice')
        .required('This field is required'),
    firstname: Yup.string().trim() 
        .min(1, 'First name cannot be empty or just whitespace')
        .max(50, 'First name must be at most 50 characters')
        .required('This field is required'),
    lastname: Yup.string().trim() 
        .min(1, 'Last name cannot be empty or just whitespace')
        .max(50, 'Last name must be at most 50 characters')
        .required('This field is required'),
    email: Yup.string()
        .email('Invalid email format')
        .required('This field is required'),
    phone: Yup.string()
        .matches(/^\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format XXX-XXX-XXXX')
        .required('This field is required'),
    address: Yup.string().trim()
        .min(1, 'Address cannot be empty or just whitespace')
        .max(50, 'Address must be at most 100 characters')
        .required('This field is required'),
    apt: Yup.string()
        .max(50, 'Apt must be at most 10 characters'),
    city: Yup.string().trim()
        .min(1, 'City cannot be empty or just whitespace')
        .max(50, 'City must be at most 50 characters')
        .required('This field is required'),
    state: Yup.string()
        .oneOf(formData['states'].map(state => state.abbreviation), 'Please select a valid state')
        .required('This field is required'),
    zip_code: Yup.string()
        .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits')
        .oneOf(zipCodesStr, 'At this moment we do not offer our service to your area.')
        .required('This field is required'),
    additional: Yup.string()
        .max(250, 'This field must be at most 250 characters'),
    about_us: Yup.string()
    .oneOf(formData['about_us'], 'Please select a valid choice')
    .required('This field is required'),
});

export default validationSchema;