import React from 'react'
import { QuoteContext } from '../../pages/Quote'
import axios from 'axios';
import { Link } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


const BookingForm = () => {
    const { setBookingSubmitted, responseData, setResponseData} = React.useContext(QuoteContext);
    const [error, setError] = React.useState(null);
    
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 7) 
    startDate.setHours(0, 0, 0, 0)

    const maxDate = new Date(startDate)
    maxDate.setDate(startDate.getDate() + 14)
    maxDate.setHours(0, 0, 0, 0)

    const choices = [
        {
            label: 'Morning',
            value: 'morning'
        }, 
        {
            label: 'Afternoon',
            value: 'afternoon'
        }
    ]

    const initialValues = {
        date1: '', 
        date2: '', 
        date3: '', 
        time1: 'morning',
        time2: 'morning',
        time3: 'morning',
        additional2: '',
    }

    const validationSchema = Yup.object().shape({
        date1: Yup.date()
            .required('Date is required')
            .typeError('Must be a valid date')
            .min(startDate, 'Date must be startDate or later')
            .max(maxDate, 'Date cannot be more than two weeks from today'),
        date2: Yup.date()
            .required('Date is required')
            .typeError('Must be a valid date')
            .min(startDate, 'Date must be startDate or later')
            .max(maxDate, 'Date cannot be more than two weeks from today'),
        date3: Yup.date()
            .required('Date is required')
            .typeError('Must be a valid date')
            .min(startDate, 'Date must be startDate or later')
            .max(maxDate, 'Date cannot be more than two weeks from today'),
        time1: Yup.string()
            .required('This field is required')
            .oneOf(choices.map(choice => choice.value), 'Please select a valid choice'),
        time2: Yup.string()
            .required('This field is required')
            .oneOf(choices.map(choice => choice.value), 'Please select a valid choice'),
        time3: Yup.string()
            .required('This field is required')
            .oneOf(choices.map(choice => choice.value), 'Please select a valid choice'),
        additional2: Yup.string()
            .max(500, 'This field must be at most 500 characters'),
    }).test('unique-date-times', 'Date and time combinations must be unique', function (values) {
        const { date1, time1, date2, time2, date3, time3 } = values;
    
        const dateTimeSet = new Set();

        const entries = [
            { date: date1, time: time1 },
            { date: date2, time: time2 },
            { date: date3, time: time3 },
        ];
    
        for (const { date, time } of entries) {
            if (date && time) {
                const dateTime = `${date.toISOString().split('T')[0]} ${time}`;
                if (dateTimeSet.has(dateTime)) {
                    return this.createError({ path: 'unique-date-times', message: 'Date and time combinations must be unique' });
                }
                dateTimeSet.add(dateTime);
            }
        }
    
        return true;
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        const updated_values = {...values, ...responseData}
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/send-booking-request/`, updated_values);
            setBookingSubmitted(true)
            setResponseData(response.data)
            setError(null)
        } catch (error) {
            if (error.response.data.non_field_errors){
                setError(error.response.data.non_field_errors[0])
            } else {
                setError('Error submitting booking request. Please try again later.')
            }
        } finally {
            setSubmitting(false);
        }
    };
    
    return (
        <Formik
                initialValues = {initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, isValid, dirty }) => (
                    <Form className='booking--form'>
                        <h2 className='fw-500 text-center mt-3'>Request a Booking</h2>
                        <p>Please choose three days and indicate whether you prefer morning or afternoon.</p>

                        {/* Days and times */}
                        <div>
                            <Row>
                                <Col sm={3} md={3}>
                                    <label htmlFor='date1' className='me-3'>1.</label>
                                    <Field 
                                        type='date' 
                                        name='date1' 
                                        id='date1' 
                                        className={`form-control ${touched.date1 && errors.date1 ? 'is-invalid' : ''}`}
                                        min={startDate.toISOString().split('T')[0]}
                                        max={maxDate.toISOString().split('T')[0]}
                                    />
                                </Col>
                                <Col sm={3} md={3}>
                                    <Field
                                        as="select"
                                        id="time1"
                                        name="time1"
                                        className={`form-control ${touched.time1 && errors.time1 ? 'is-invalid' : ''}`}
                                    >
                                        {choices.map(({label, value}) => (
                                            <option key={`time1-${value}`} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                    </Field>
                                </Col>
                            </Row>
                            <ErrorMessage name='date1'>
                                {msg => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>
                            <ErrorMessage name='time1'>
                                {msg => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>

                            <Row>
                                <Col sm={3} md={3}>
                                    <label htmlFor='date2' className='me-3'>2.</label>
                                    <Field 
                                        type='date' 
                                        name='date2' 
                                        id='date2'
                                        className={`form-control ${touched.date2 && errors.date2 ? 'is-invalid' : ''}`}
                                        min={startDate.toISOString().split('T')[0]}
                                        max={maxDate.toISOString().split('T')[0]} 
                                    />
                                </Col>
                                <Col sm={3} md={3}>
                                    <Field
                                        as="select"
                                        id="time2"
                                        name="time2"
                                        className={`form-control ${touched.time2 && errors.time2 ? 'is-invalid' : ''}`}
                                    >
                                        {choices.map(({label, value}) => (
                                            <option key={`time2-${value}`} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                    </Field>
                                </Col>
                            </Row>
                            <ErrorMessage name='date2'>
                                {msg => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>
                            <ErrorMessage name='time2'>
                                {msg => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>

                            <Row>
                                <Col sm={3} md={3}>
                                    <label htmlFor='date3' className='me-3'>3.</label>
                                    <Field 
                                        type='date' 
                                        name='date3' 
                                        id='date3'
                                        className={`form-control ${touched.date3 && errors.date3 ? 'is-invalid' : ''}`}
                                        min={startDate.toISOString().split('T')[0]}
                                        max={maxDate.toISOString().split('T')[0]}
                                    />
                                </Col>
                                <Col sm={3} md={3}>
                                    <Field
                                        as="select"
                                        id="time3"
                                        name="time3"
                                        className={`form-control ${touched.time3 && errors.time3 ? 'is-invalid' : ''}`}
                                    >
                                        {choices.map(({label, value}) => (
                                            <option key={`time3-${value}`} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                    </Field>
                                </Col>
                            </Row>
                            <ErrorMessage name='date3'>
                                {msg => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>
                            <ErrorMessage name='time3'>
                                {msg => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>
                            {errors['unique-date-times'] && <div className="text-danger">{errors['unique-date-times']}</div>}

                            <Row className='additional'>
                                <label htmlFor='additional2'>Additonal Info</label>
                                <Field 
                                    id='additional2' 
                                    name='additional2' 
                                    as='textarea'  
                                    rows='5'
                                    className={`form-control ${touched.additional2 && errors.additional2 ? 'is-invalid' : ''}`} 
                                    maxLength={250} 
                                />
                                <ErrorMessage name='additional2'>
                                    {msg => <div className="text-danger">{msg}</div>}
                                </ErrorMessage>
                            </Row>
                        </div>

                        <div className='m-3'>
                            <p>By continuing, you agree to 
                                    our <Link to='/terms' className='link'>Terms & Conditions</Link> and <Link to='/privacy-policy' className='link'>Privacy Policy</Link>, and 
                                confirm that you are at least 18 years of age. You also acknowledge that this is <span className='text-danger'>NOT</span> a booking.
                                Dani's Home Cleaning Services will contact you to finalize the booking process.</p>
                        </div>
                        {error && <p className='text-danger text-center'>{error}</p>}

                        {/* Submit */}
                        <div className='booking--button'>
                            <Button type='submit' variant='outline-primary' disabled={isSubmitting || !dirty || !isValid}>
                                {isSubmitting ? 'Submitting Request...' : 'Submit Request'}
                            </Button>
                        </div>
                    </Form>
                )}
        </Formik>
    )
}

export default BookingForm
