import React from 'react'
import PageTitle from '../components/PageTitle';
import Container from 'react-bootstrap/Container';
import ProvideService from '../components/ProvideService';
import QuoteForm from '../components/Quote/QuoteForm';
import BookingForm from '../components/Quote/BookingForm';
import QuoteReceipt from '../components/Quote/QuoteReceipt';
import ReloadButton from '../components/Quote/ReloadForm';
import BookingReceipt from '../components/Quote/BookingReceipt';
import ScrollToTopAlways from '../components/ScrollToTopAlways'

const QuoteContext = React.createContext()

const Quote = () => {
    const [quoteSubmitted, setQuoteSubmitted] = React.useState(false)
    const [bookingSubmitted, setBookingSubmitted] = React.useState(false)
    const [responseData, setResponseData] = React.useState(null)

    return( 
        <QuoteContext.Provider value={{
                quoteSubmitted, setQuoteSubmitted, 
                bookingSubmitted, setBookingSubmitted, 
                responseData, setResponseData}}>
            <Container>
                <h1>Instant Quote</h1>
                <PageTitle title='DHCS - Quote' />
                {(!quoteSubmitted && !bookingSubmitted) && <ProvideService />}
                {!bookingSubmitted?
                    <>
                        <Container className='quote shadow'>
                            { !quoteSubmitted?
                                <QuoteForm />:
                                <>
                                    <QuoteReceipt />
                                    <div className='quote--button'>
                                        <ReloadButton/>
                                    </div>
                                </> }
                        </Container>
                        { quoteSubmitted && 
                            <Container className='quote shadow mt-4'>
                                <ScrollToTopAlways />
                                <BookingForm />
                            </Container> }
                    </>:
                    <Container className='quote shadow'>
                        <ScrollToTopAlways />
                        <BookingReceipt />
                    </Container>
                }
            </Container>
        </QuoteContext.Provider>
    )
}

export default Quote;
export { QuoteContext }