import React from 'react'
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import zipCodes from '../quoteUtils/zipCodes';

export default function ProvideService(){
    const [valid, setValid] = React.useState([false, 0])
    const [zip, setZip] = React.useState('')
    const handlePaste = (e) => e.preventDefault();

    function handleChange(e) {
        const { value } = e.target;
        if (/^\d*$/.test(value) && value.length <= 5) {
            setZip(value);
            setValid((prevValid) => [prevValid[0], value.length])
        }
    }

    if (zipCodes.includes(Number(zip)) && !valid[0]){
        setValid((prevValid) => [true, prevValid[1]])
    } else if (!zipCodes.includes(Number(zip)) && valid[0]){
        setValid((prevValid) => [false, prevValid[1]])
    }

    return (
        <Container className='service quote__480 shadow'>
            <h2 className='fw-500'>Find out if we provide our service to your area</h2>
                
            <Form validated={valid[0]}>
                <Form.Group controlId="zip-code">
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control
                        type='text'
                        onChange={handleChange}
                        value={zip}
                        onPaste={handlePaste}
                        isInvalid={!valid}
                        className={(!valid[0] && valid[1] === 5) ? 'invalid-text' : ''}
                    />
                    <Form.Control.Feedback type='invalid'>
                        Please enter a valid 5-digit zip code.
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
            {   valid[1] === 5 && 
                (
                    valid[0] ? 
                    <p className='valid'>We provide our service to your area!</p>:
                    <p className='invalid'>At this moment we do not offer our service to your area.</p>
                )
            }
        </Container>
    )
}